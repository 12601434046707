.user__profile {
	display: flex;
	justify-content: center;
}
.user__profile-details {
	display: flex;
	width: 800px;
	flex-direction: column;
	justify-content: center;
}

.user__info {
	display: flex;
	align-items: center;
	margin: 15px 0px;
}
.user__icon {
	font-size: 35px !important;
	display: flex;
	align-items: center;
	justify-content: center;
}

.user__icon .icon-description {
	font-size: 20px;
	margin-bottom: 5px;
	margin-left: 10px;
	font-weight: 600;
}

.user__info-title {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-left: 150px;
}

.edit-profile {
	margin-top: 40px;
	margin-left: 300px;
}

.name-item {
	margin-left: -3px;
}
.login-item {
	margin-left: -3px;
}
.gateway-item {
	margin-left: -39px;
}

.edit-button {
	width: 200px;
	margin-top: 5px;
	margin-bottom: 40px;
	margin-left: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 5px;
	height: 40px;
	color: white;
	background-color: orange;
}
